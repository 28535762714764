@import "../Palette"

.history-and-comment
    display: flex
    flex-direction: column
    gap: 1em

    .buttons
        border-radius: 1em
        background-color: $c-emp
        color: $c-bg
        border: 2px solid $c-emp

        display: flex

        button
            flex: 1
            padding: 0.5em 1em
            border-radius: 2em
            border: none
            font-size: 0.8em
            font-weight: bold
            cursor: pointer

            transition: all 0.3s ease-in-out

            &.active
                background-color: $c-bg
                color: $c-emp

    .comment-input
        position: relative

        button
            position: absolute
            right: 1em
            bottom: 1em
            cursor: pointer
            color: $c-emp
            z-index: 100
