@import "../Palette"
@import "../App"

$box-color: rgba($c-text, 0.5)
$bg-color: $c-lighter

.comment-entry
    @extend .card-entry
    position: relative

    &::after
        content: ''
        position: absolute
        border-width: 10px 0 10px 10px
        border-style: solid
        border-color: transparent transparent transparent $bg-color
        display: block
        top: 50% // Position at the center of the left side
        right: -10px

    &.sent::after
        right: unset
        left: -10px // Adjust left position
        transform: translateY(-20%) rotate(180deg)

    .sub
        color: $c-sub
        font-size: 0.8em

        display: flex
        gap: 0.5em
        align-items: center
        justify-content: space-between

    .c-text
        max-width: 300px
        word-break: break-word
