@import "../Palette";

.tag-card {
  height: 130px;
  width: 200px;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  transition: all .5s ease-in-out;

  h2 {
    margin: 0;
  }

  .count {
    font-size: 0.9rem;
    color: $c-sub;
  }

  .btn {
    font-weight: bold;
    float: right;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    border: 1px solid $c-text;
    border-radius: 5px;
    padding: 1px;
    transition: all .2s ease-in-out;
  }

  .btn:hover {
    transform: scale(1.05);
  }
}

.tag-card.active {
  // Make the card float
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  transform: translateY(-5px);
}

@media only screen and (max-width: 768px) {
  .tag-card {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
}
