// style.scss
@import "../Palette";

.library-body {
  color: $c-text;
  padding-bottom: 200px;

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .tags-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 2rem;

      .tags {
        display: flex;
      }
    }

    .flows-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

h2 {
  margin-bottom: 1rem;
  font-weight: bold;
}

// For tablet view
@media only screen and (max-width: 768px) {
  .library-body {
    .main-container {
      flex-direction: column;

      .header {
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 1rem;
        width: 100%;
  
        .title {
          margin-bottom: 1rem;
        }
      }

      .flows-container {
        width: 100%;
        padding: 0;
      }

      .tags-container {
        width: 100%;
        height: unset;
        
        .tags {
          flex-direction: column;
        }
      }
    }
  }
}
