@import "../Palette"

.edit-flow-meta
    gap: 1rem

    .field
        .label
            display: block
            font-size: 0.8em

        input, textarea
            background: rgba(white, 0.1)
            color: $c-text
            width: 100%
            border-radius: 0.5rem
            padding: 0.3rem 0.5rem

            border: 1px solid rgba($c-text, 0.1)

    .tags
        margin-top: 1rem
