@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Sen&display=swap')
@import "Palette"

body
  margin: 0
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background-color: $c-bg

  font-size: 16px

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
