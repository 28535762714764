@import "../Palette"

@media only screen and (max-width: 768px)
    .flow-editor
        flex-direction: column-reverse
        gap: 2em

        .panel
            max-width: 500px
            width: 100%
            margin: auto

.flow-editor
    display: flex
    gap: 3em
    border-radius: 1em

    .editor
        flex: 1
        position: relative
        border-radius: 1em
        overflow: hidden

        img
            width: 100%
            height: 100%
            border-radius: 1em
            object-fit: cover

        .drag-notice
            position: absolute
            inset: 0
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            backdrop-filter: blur(5px)
            background: rgba($c-bg, 0.5)

    .panel
        display: flex
        flex-direction: column
        gap: 2em

        .info
            display: flex
            flex-direction: column
            gap: 1em
            max-width: 400px

            .name
                font-weight: bold
                font-size: 1.2em

            .description, .meta
                font-size: 0.9em

            .meta
                color: $c-sub

            .stats
                display: flex
                gap: 0.3em
                align-items: center

                color: $c-sub
