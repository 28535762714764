$c0: #111
$c-bg: #1c1c1c
$c-emp: #B0C4B1
$c3: #748CAB
$c-text: #F0EBD8
$c-sub: rgba($c-text, 0.5)

$c-lighter: rgba(white, 0.08)


$c-error: palevioletred

.c-emp
    color: $c-emp

.c-text
    color: $c-text

.c-bg
    color: $c-bg

.c-error
    color: $c-error

.c-sub
    color: $c-sub

.bg-emp
    background-color: $c-emp

.bg-text
    background-color: $c-text

.bg-bg
    background-color: $c-bg
