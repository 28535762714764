@import "../Palette"

.float-control-wrapper
    position: fixed
    left: 0
    right: 0
    bottom: 3rem
    display: flex
    justify-content: center

.float-control
    display: flex
    gap: 0.5rem
