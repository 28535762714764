@import "../Palette"

// Card
.flow
    color: $c-text
    display: flex
    flex-direction: column
    padding: 1.2rem

    height: 210px
    text-align: left

    border-radius: 1rem
    border: 1px solid $c-text
    overflow: hidden

    .info
        width: 100%

        .name
            // Don't wrap text on overflow
            overflow: hidden
            display: -webkit-box
            -webkit-line-clamp: 2
            line-clamp: 2
            -webkit-box-orient: vertical
            text-overflow: ellipsis

            font-weight: bold
            font-size: 1.1em

        .description
            font-size: 0.8em
            color: $c-sub

    .tags
        margin-bottom: 0.5em

    .extended
        display: flex
        gap: 0.5rem

        .id, .sub
            color: $c-sub

    .votes
        display: flex
        align-items: center

.flow.importing
    // Height can be lower because importing flows doesn't have description
    height: 150px
