@import "../Palette"

.drop-area
    z-index: 100

    border: 2px dashed $c-emp
    border-radius: 1rem
    background: rgba(white, 0.2)

    // Blur
    backdrop-filter: blur(10px)

    position: fixed
    inset: 0
    margin: 5rem

    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: center

    font-size: 3em
    color: $c-emp

.drop-area.error
    border-color: $c-error
    color: $c-error

.upload-btn
    position: fixed
    bottom: 3rem
    right: 3rem

    z-index: 110

    input
        display: none
