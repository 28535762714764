@tailwind base
@tailwind components
@tailwind utilities
@import "Palette"

.app-wrapper
  padding: 2.5rem

.app-wrapper-wrapper
  background: $c-bg
  color: $c-text
  width: 100%
  min-height: 100vh
  max-width: 1200px
  margin: 0 auto

.nav
  margin-bottom: 2rem
  display: flex
  justify-content: space-between
  align-items: center

@media only screen and (max-width: 768px)
  .nav
    flex-direction: column
    gap: 1rem

.nav:not(:first-child)
  margin-top: 2rem

.title
  font-size: 1.5em
  font-weight: bold
  color: $c-emp

.interactive
  height: 40px
  padding: 0.5rem 0.8rem
  display: inline-flex
  gap: 0.5rem
  align-items: center
  justify-content: center

  border-radius: 0.5rem
  border: 1px solid $c-text
  color: $c-text
  background: $c-bg

  transition: all 0.3s ease-in-out

.btn
  cursor: pointer

.btn.styled
  @extend .interactive

.btn.styled.active
  padding: 0.5rem 1rem
  border: none
  background: $c-text
  color: $c-bg

.btn.styled.active.emp
  background: $c-emp

.btn.styled:hover
  transform: scale(1.05)

.input
  @extend .interactive

.input::placeholder
  color: $c-sub
  font-size: 0.8em

textarea.input
  background: $c-lighter
  height: max-content
  width: 100%
  border-color: transparent
  outline: none

  transition: all 0.3s ease-in-out

textarea.input:focus-visible
  border-color: rgba($c-text, 0.5)
  outline: none

.floating-window-wrapper
  position: fixed
  inset: 0

  display: flex
  justify-content: center
  align-items: center

  backdrop-filter: blur(8px)
  z-index: 150

  .floating-window
    color: $c-text
    border: 1px solid rgb($c-text, 0.5)
    border-radius: 1rem
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5)
    background: $c-bg

    padding: 2rem
    max-width: 500px
    min-width: min(400px, 100vw)

    display: flex
    flex-direction: column
    overflow: hidden
    gap: 1.2rem

  .title
    font-size: 1.5em
    font-weight: bold
    color: $c-emp

    display: flex
    justify-content: space-between
    align-items: center

    .btn
      font-size: 0.8em
      padding: 0.2rem
      color: $c-bg
      background: $c-emp
      border-radius: 100px

a:not(.nota)
  color: $c-emp
  font-style: italic

.flows-grid
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  gap: 2rem

.tag-cards-grid
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
  gap: 1rem

.comments-grid
  margin-top: 1em
  display: flex
  flex-direction: column
  gap: 1em

.card-entry
  text-align: left

  background-color: $c-lighter
  border-radius: 0.5rem
  padding: 0.5em 1em

.tags
  display: flex
  gap: 0.5em
  flex-wrap: wrap

  .tag-entry
    background-color: $c-lighter
    padding: 0.2em 0.5em
    border-radius: 0.5em
    font-size: 0.8em

.clickable
  cursor: pointer
